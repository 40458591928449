<template>
    <span>
        <span @click.prevent=" activeVideoModal ">
            <slot>
                <a>视频号显示设置</a>
            </slot>
        </span>

        <at-modal width="488px" title="主页视频号显示" @ok="videoConfirm" v-model="showVideoModal">
            <div class="xs-item">
                <div class="xs-title">请输入同主体视频号ID <small>（必填）</small></div>
                <div class="xs-content">
                    <at-input class="full-with" v-model="params.app_id"/>
                </div>
            </div>

            <div class="xs-item">
                <div class="xs-title">请输入视频链接ID <small>（选填）</small></div>
                <div class="xs-content">
                    <at-input class="full-with" v-model="params.video_id"/>
                </div>
            </div>

            <div class="xs-item">
                <a-radio-group :default-value="0" v-model="params.enabled">
                    <a-radio :value="1">
                        <span class="radio-text">显示</span>
                    </a-radio>
                    <a-radio :value="0">
                        <span class="radio-text">不显示</span>
                    </a-radio>
                </a-radio-group>
            </div>

            <div class="xs-item">
                <div>①仅添加视频号ID：实现跳转到同主体视频号主页；要求主体一致！</div>
                <div>②添加 “视频号ID+视频链接ID” 调转到相应视频号视频，无主体要求</div>
            </div>

        </at-modal>

    </span>
</template>

<script>
import AtModal from "@/components/AtModal.vue";
import AtInput from "@/components/AtInput.vue";
import api from "@/repo/api";

export default {
    name: "WechatVideo",
    components: {AtInput, AtModal},
    data() {
        return {
            showVideoModal: false,
            params: {}
        }
    },
    methods: {
        activeVideoModal() {


            this.$loading.show()

            api.get('/ast-app/app-info', (data) => {

                this.$loading.hide()

                let info = data.data

                this.params = {
                    app_id: info.video_account.app_id,
                    video_id: info.video_account.video_id,
                    enabled: info.video_account.enabled
                }


                this.showVideoModal = true

            });
        },
        videoConfirm() {

            this.$loading.show()

            api.post('/ast-app/update-video-account', this.params, (data) => {

                this.$loading.hide()
                this.$message.data(data)

                if (data.code === 0) {
                    this.showVideoModal = false
                    this.$emit('change', {
                        'video_account': {
                            app_id: this.params.app_id,
                            video_id: this.params.video_id,
                            enabled: this.params.enabled,
                        }
                    })
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
.xs-item {
    margin-bottom: 24px;
}

.xs-item /deep/ .ant-radio-wrapper-checked .radio-text {
    color: #333333;
}

.xs-item {
    margin-bottom: 24px;
}

.xs-title {
    margin-bottom: 16px;
}
</style>