<template>
    <div>
        <div class="page-title">基础设置</div>

        <div v-if="loading" class="loading-block">
            <loader/>
        </div>
        <div v-else class="bs-box">

            <div class="bs-row" v-if="!info.has_error">

                <div class="bs-title">小程序名称</div>

                <div class="bs-content">

                    <div class="bs-summary">
                        {{ info.nickname }}
                    </div>

                    <div class="bs-limit">
                        <div>
                            一年内可申请修改{{ info.nickname_info.modify_quota }}次 <br>
                            今年还可修改{{ nickNameModLost }}次
                        </div>

                        <role-owner>
                            <a v-if=" nickNameModLost > 0 " class="bs-edit" href="javascript:"
                               @click="showName">修改</a>
                        </role-owner>
                    </div>
                </div>
            </div>

            <div class="bs-row" v-if="!info.has_error">

                <div class="bs-title">小程序码</div>

                <div class="bs-content">

                    <div class="bs-summary">
                        <img width="100px" :src=" $qrcode('pages/index/index') " alt="">
                    </div>

                    <div class="bs-limit">
                        <div>
                            微信扫码直达小程序
                        </div>


                        <a target="_blank" :href="$qrcode('pages/index/index',`${info.nickname}小程序直达码`)">下载</a>

                    </div>
                </div>
            </div>


            <div class="bs-row" v-if="!info.has_error">

                <div class="bs-title">小程序头像</div>

                <div class="bs-content">

                    <div class="bs-summary">
                        <div class="head-img">
                            <img v-if="info.head_image_info.head_image_url"
                                 :src=" info.head_image_info.head_image_url "
                                 alt="">
                        </div>
                    </div>

                    <div class="bs-limit">
                        <div>
                            一年内可申请修改{{ info.head_image_info.modify_quota }}次 <br>
                            今年还可修改{{ headImgModLost }}次
                        </div>

                        <role-owner>
                            <a v-if=" headImgModLost > 0 " @click="showHead" class="bs-edit" href="javascript:">修改</a>
                        </role-owner>
                    </div>
                </div>
            </div>


            <div class="bs-row" v-if="!info.has_error">

                <div class="bs-title">介绍</div>

                <div class="bs-content">

                    <div class="bs-summary">
                        {{ info.signature_info.signature }}
                    </div>

                    <div class="bs-limit">
                        <div>
                            一年内可申请修改{{ info.signature_info.modify_quota }}次 <br>
                            今年还可修改{{ signatureModLost }}次
                        </div>

                        <role-owner>
                            <a v-if=" signatureModLost > 0 " @click="showSummary" class="bs-edit"
                               href="javascript:">修改</a>
                        </role-owner>

                    </div>
                </div>
            </div>

            <div class="bs-row" v-if="!info.has_error">

                <div class="bs-title">
                    小程序是否 <br> 允许被搜索
                </div>

                <div class="bs-content">

                    <div class="bs-summary">
                        {{ info.search_disabled === 0 ? '允许' : '不允许' }}
                    </div>

                    <div class="bs-limit">
                        <div>
                            <template v-if="info.search_disabled">
                                不允许被搜索，用户只能通过分享链接或扫二维码进入
                            </template>
                            <template v-else>
                                允许被搜索,用户可以通过搜索进入
                            </template>
                        </div>

                        <role-owner>
                            <a @click="showSearch" class="bs-edit" href="javascript:">修改</a>
                        </role-owner>
                    </div>
                </div>
            </div>

            <div class="bs-row">

                <div class="bs-title">
                    主页视频号显示
                </div>

                <div class="bs-content">

                    <div class="bs-summary">
                        {{ info.video_account.enabled ? '显示' : '不显示' }}
                    </div>

                    <div class="bs-limit">
                        <div>
                            视频号ID: {{ info.video_account.app_id || ' 暂未设置 ' }}
                            <a @click="showVideoTravel = true" class="video-tips">如何查找视频号ID？</a>
                        </div>

                        <role-owner>
                            <wechat-video @change="getInfo">
                                <a class="bs-edit" href="javascript:">修改</a>
                            </wechat-video>
                        </role-owner>
                    </div>
                </div>
            </div>

            <div class="bs-row" v-if=" info.info ">

                <div class="bs-title">微信认证</div>

                <div class="bs-content">

                    <div class="bs-summary">
                        {{ info.info.mp_verify >= 1 ? '已认证' : '认证已过期' }}
                    </div>

                    <div class="bs-limit">
                        <div>将影响本小程序新版本发布和“被搜索”“被分享”能力</div>

                        <role-owner>
                            <template v-if=" !(info.info.mp_verify >= 1) ">

                                <router-link class="bs-edit" to="/ast/xcx/auth">去认证</router-link>

                            </template>
                        </role-owner>

                    </div>
                </div>
            </div>

            <div class="bs-row" v-if=" info.info ">

                <div class="bs-title">
                    小程序备案
                </div>

                <div class="bs-content">

                    <div class="bs-summary">
                        {{ info.info.icp_status === 6 ? '已备案' : '未备案' }}
                    </div>

                    <div class="bs-limit">
                        <div>
                            <div>按国家互联网监管政策</div>
                            <div>小程序必须通过政府备案才可正常发布使用</div>
                        </div>

                        <!--  <a @click="showVideo" class="bs-edit" href="javascript:">去备案</a>-->
                    </div>
                </div>
            </div>
            <div class="bs-row">
                <div class="bs-title">小程序版本号</div>
                <div class="bs-content">
                    <div class="bs-summary">
                        <template v-if="app.rel_version">
                            v{{ app.rel_version }}
                        </template>
                        <template v-else>
                            暂无版本
                        </template>
                    </div>
                    <div class="bs-limit">
                        <div>小程序线上版本号</div>
                    </div>
                </div>
            </div>
            <div class="bs-row">
                <div class="bs-title">微信支付商户号</div>
                <div class="bs-content">
                    <div class="bs-summary">
                        <template v-if="app.sub_mchid">
                            {{ app.sub_mchid }}
                        </template>
                        <template v-else>-</template>
                    </div>
                    <div class="bs-limit">
                        <div>
                            <div>
                                <span>分账：</span>
                                <span v-if="app.info.pay_share_enabled">已开启</span>
                            </div>
                            <div>
                                <span>扫码支付：</span>
                                <span v-if="app.info.pay_native_enabled">已开启</span>
                            </div>
                        </div>

                        <a class="bs-link" target="_blank" href="https://pay.weixin.qq.com">前往支付商户后台</a>
                    </div>
                </div>
            </div>
            <div class="bs-row">
                <div class="bs-title">系统有效期</div>
                <div class="bs-content">
                    <div class="bs-summary">
                        <template v-if="app.exp_end_at">
                            {{ app.exp_end_at.split(' ')[0] }}
                        </template>
                        <template v-else>-</template>
                    </div>
                    <div class="bs-limit">
                        <div>小程序到期时间</div>


                        <role-owner>
                            <renew class="bs-edit"/>
                        </role-owner>
                    </div>
                </div>
            </div>


            <template v-if="!info.has_qa">
                <q-a/>
            </template>

        </div>

        <at-modal title="修改名称" width="408px" @ok="nameConfirm" v-model="showNameModal">
            <div class="xs-item">
                <div class="xs-title">请输入小程序名称</div>
                <div class="xs-content summary-content">
                    <a-textarea :maxLength="20" class="summary-area name-area" v-model="params.name"></a-textarea>
                </div>
            </div>
            <template v-if="nameError">
                <div class="summary-warning-1">
                    <template>{{ nameError }}</template>
                </div>
                <div class="summary-warning-2">
                    请更换其他名称或前往
                    <a target="_blank" href="https://mp.weixin.qq.com/">微信公众平台</a>
                    修改
                </div>

            </template>

        </at-modal>

        <at-modal title="修改介绍" width="640px" v-model="showSummaryModal" @ok="summaryConfirm">
            <div class="xs-item">
                <div class="xs-title">请输入小程序介绍</div>
                <div class="xs-content summary-content">
                    <a-textarea :maxLength="120" class="summary-area" v-model="params.summary"></a-textarea>

                    <span v-if="params.summary !== undefined "
                          class="summary-count">{{ params.summary.length }} / 120</span>
                </div>
            </div>
            <div class="summary-warning-1">*功能介绍长度不超过120个字符</div>
            <div class="summary-warning-2">请确认介绍内容不含国家相关法律法规禁止内容</div>

        </at-modal>

        <at-modal v-model="showSearchModal" title="隐私设置" @ok="searchConfirm">
            <div class="gth"></div>
            <template v-if="info.search_disabled">
                <div class="confirm-title">确认开启允许被搜索吗？</div>
                <div class="confirm-text">
                    设置小程序允许被搜索后，用户将可以在微信内通过名称搜索到小程序账号，设置将在5分钟内生效
                </div>
            </template>
            <template v-else>
                <div class="confirm-title">确认关闭允许被搜索吗？</div>
                <div class="confirm-text">
                    设置小程序不允许被搜索后，用户将不可在微信内通过名称搜索到小程序账号，设置将在5分钟内生效
                </div>
            </template>

        </at-modal>

        <at-modal width="640px" title="修改头像" v-model="showHeadModal" @ok="headImageConfirm">
            <div class="head-tips">
                新头像不允许涉及政治敏感与色情; <br>
                图片格式必须为：png,bmp,jpeg,jpg,gif；不可大于2M；实际头像始终为正方形<br>
                建议使用png格式图片，以保持最佳效果；建议图片尺寸为144px*144px
            </div>

            <a-upload :showUploadList="false" name="file" :before-upload="beforeUpload">
                <button class="btn btn-white btn-upload">上传文件</button>
            </a-upload>

            <div class="head-box">
                <div class="head-img-1">
                    <template v-if="base64">
                        <img :src="base64" alt="">
                    </template>
                </div>
                <div class="head-img-2">
                    <div class="head-img-title">头像预览</div>
                    <div class="head-img-2-box">

                        <div class="head-img-2-1">
                            <template v-if="base64">
                                <img :src="base64" alt="">
                            </template>
                        </div>

                        <div class="head-img-2-2">
                            <template v-if="base64">
                                <img :src="base64" alt="">
                            </template>
                        </div>

                    </div>
                </div>
            </div>

        </at-modal>

        <div v-if="showVideoTravel" class="video-travel">
            <img src="/assets/images/travel.png" alt="">
            <a @click="showVideoTravel = false" class="video-travel-close"></a>
        </div>

    </div>
</template>

<script>
import api from "../../../repo/api";
import Loader from "../../../components/Loader";
import AtModal from "../../../components/AtModal";
import QA from "@/views/AST/Xcx/Com/QA.vue";
import Renew from "@/views/AST/Xcx/Com/Renew.vue";
import RoleOwner from "@/components/roles/RoleOwner.vue";
import WechatVideo from "@/views/AST/Xcx/Items/WechatVideo.vue";

export default {
    name: "Name",
    components: {WechatVideo, RoleOwner, Renew, QA, AtModal, Loader},
    props: {
        app: Object
    },
    data() {
        return {
            showVideoTravel: false,
            info: {},
            loading: true,
            showSearchModal: false,
            showSummaryModal: false,
            showNameModal: false,
            showHeadModal: false,
            params: {
                app_id: '',
                video_id: '',
                enabled: 0,
                summary: '',
                name: '',
            },
            nameError: "",
            base64: '',
        }
    },
    mounted() {
        this.getInfo()
    },
    methods: {
        showHead() {
            this.base64 = ''
            this.showHeadModal = true
        },
        headImageConfirm() {
            this.$loading.show()

            api.post('/ast-app/update-head-img', {
                image: this.base64
            }, (data) => {

                this.$loading.hide()
                this.$message.data(data)

                if (data.code === 0) {

                    this.showHeadModal = false
                    this.info.head_image_info.head_image_url = this.base64
                    this.info.head_image_info.modify_used_count++

                    this.base64 = ''
                }
            })
        },
        beforeUpload(p) {

            if ((p.size / 1024 / 1024) > 2) {
                this.$message.error('图片太大了。');
                return false;
            }

            this.getBase64(p).then((base64) => {

                this.base64 = base64

                this.$forceUpdate()
            })

            return false;
        },
        nameConfirm() {
            this.$loading.show()

            api.post('/ast-app/update-name', this.params, (data) => {

                this.$loading.hide()

                if (data.code === 0) {

                    this.$message.data(data)

                    this.showNameModal = false
                    this.info.nickname = this.params.name
                    this.info.nickname_info.modify_used_count++
                } else {
                    this.nameError = data.msg
                }
            })
        },
        showName() {
            this.params = {
                name: ''
            }
            this.showNameModal = true
        },
        summaryConfirm() {
            this.$loading.show()

            api.post('/ast-app/update-summary', this.params, (data) => {

                this.$loading.hide()

                this.$message.data(data)

                if (data.code === 0) {
                    this.showSummaryModal = false
                    this.info.signature_info.signature = this.params.summary
                    this.info.signature_info.modify_used_count++
                }
            })
        },
        showSummary() {
            this.params = {
                summary: ''
            }
            this.showSummaryModal = true
        },
        showSearch() {
            this.showSearchModal = true
        },
        searchConfirm() {
            this.$loading.show()

            api.post('/ast-app/update-search', {enabled: this.info.search_disabled ? 1 : 0}, (data) => {

                this.$loading.hide()
                this.$message.data(data)

                if (data.code === 0) {
                    this.showSearchModal = false
                    this.info.search_disabled = this.info.search_disabled === 0 ? 1 : 0
                }

            });

        },
        getBase64(file) {

            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = error => reject(error);
            });

        },
        getInfo() {
            this.loading = true

            api.get('/ast-app/app-info', (data) => {

                if (data.code === 36) {

                    this.$router.push('/ast/reg')

                    return
                }

                this.info = data.data

                this.loading = false

                if (!data.data.yc_shop_id) {
                    this.initShop()
                }
            });
        },
        initShop() {
            api.post('/ast-app/init-shop', {}, data => {
                console.log(data);
            })
        },
    },
    computed: {
        nickNameModLost() {
            return this.info.nickname_info.modify_quota - this.info.nickname_info.modify_used_count
        },
        headImgModLost() {
            return this.info.head_image_info.modify_quota - this.info.head_image_info.modify_used_count
        },
        signatureModLost() {
            return this.info.signature_info.modify_quota - this.info.signature_info.modify_used_count
        }
    }
}
</script>

<style lang="less" scoped>
.bs-box {
    max-width: 900px;
}

.head-img {
    width: 56px;
    height: 56px;
    overflow: hidden;
    border-radius: 50%;
    background-color: #dddddd;
    border: 1px solid #dddddd;

    img {
        width: 100%;
    }
}

.bs-row {
    min-height: 80px;
    display: flex;
    flex-direction: row;
}

.bs-content {
    padding: 16px 24px 16px 0;
    border-bottom: 1px solid #EEEEEE;
    flex: 1;

    display: flex;
    align-items: center;
    flex-direction: row;

}

.bs-title {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 16px;
    width: 70px;
    margin-right: 66px;
    display: flex;
    justify-content: left;
    align-items: center;
}

.bs-summary {
    width: 240px;
    margin-right: 56px;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
}


.bs-limit {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.bs-edit {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
}

.xs-item {
    margin-bottom: 24px;
}

.xs-title {
    margin-bottom: 16px;
}

.radio-text {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
}

.xs-item /deep/ .ant-radio-wrapper-checked .radio-text {
    color: #333333;
}

.confirm-title {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
    text-align: center;
}

.gth {
    width: 56px;
    height: 56px;
    margin: 40px auto 32px;
    background-image: url("/assets/icons/gth.png");
    background-size: 56px;
}

.confirm-text {
    width: 368px;
    margin: 32px auto 72px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
}

.summary-area {
    height: 160px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    outline: none;
    border-radius: 0;
    box-shadow: none !important;
}

.name-area {
    height: 100px;
    resize: none;
}

.summary-content {
    position: relative;
}

.summary-count {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.25);
    line-height: 22px;
    position: absolute;
    right: 12px;
    bottom: 6px;
}

.summary-warning-1 {
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 400;
    color: #E31D1A;
    line-height: 14px;
}

.summary-warning-2 {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.head-tips {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
}

.btn-upload {
    width: 96px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #333333;
    line-height: 32px;
    padding: 0;
    margin-top: 16px;
    font-size: 14px;
    color: #333333;

    &:hover {
        color: #333333;
    }
}

.head-box {
    margin-top: 20px;
    display: flex;
    flex-direction: row;

    img {
        width: 100%;
        height: 100%;
    }
}

.head-img-title {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
}

.head-img-1 {
    width: 200px;
    height: 200px;
    background: #F5F5F5;
    margin-right: 24px;
    overflow: hidden;
}

.head-img-2 {
    background: #F5F5F5;
    flex: 1;
    height: 200px;
    padding-left: 24px;
}

.head-img-2-box {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.head-img-2-1 {
    width: 144px;
    height: 144px;
    background: #FFFFFF;
    border-radius: 8px;
    margin-right: 28px;
    overflow: hidden;
}

.head-img-2-2 {
    width: 144px;
    height: 144px;
    background: #FFFFFF;
    border-radius: 50%;
    overflow: hidden;
}

.video-tips {
    text-decoration: underline;
    margin-left: 15px;
    color: #999999;

    padding-left: 19px;

    background-image: url("/assets/icons/tips-btn.png");
    background-size: 16px;
    background-repeat: no-repeat;
    background-position: 0 0;
}

.video-travel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    z-index: 999;

    img {
        width: 1271px;
        position: absolute;
        top: 255px;
        left: 60px;
    }
}

.video-travel-close {
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.2);
    background-image: url("/assets/icons/tips-modal-close.png");
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 40px;
    right: 40px;
    border-radius: 50%;
}

.bs-link {
    text-decoration: underline;
    color: #999999;
}
</style>