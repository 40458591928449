<template>
    <div>
        <a-modal v-model="showQaModal" @ok="save">
            <div slot="title">
                <span>主理人信息</span>
                <small class="tips">(便于更有针对性的后期运营帮扶及资源对接，请仔细填写)</small>
            </div>

            <div>
                <div class="at-item-two">
                    <div class="at-item">
                        <div class="at-item-title">姓名</div>
                        <div class="at-item-content">
                            <at-input v-model="params.name" class="full-with"/>
                        </div>
                    </div>
                    <div class="at-item">
                        <div class="at-item-title">电话</div>
                        <div class="at-item-content">
                            <at-input v-model="params.phone" class="full-with"/>
                        </div>
                    </div>
                </div>

                <div class="at-item">
                    <div class="at-item-title">经营形式</div>
                    <div class="at-item-content">

                        <a-select class="at-select full-with" v-model="params.ind">
                            <template v-for="(name) in ind">
                                <a-select-option :key="name" :value="name">{{ name }}</a-select-option>
                            </template>
                        </a-select>

                        <at-input class="other-input" v-model="params.ind_other" v-if="params.ind === '其它业态' "
                                  placeholder="请输入业务类型"/>

                    </div>
                </div>

                <div class="at-item">
                    <div class="at-item-title">所在区域</div>
                    <div class="input-group">
                        <a-select class="at-select province-sel" v-model="params.province" @change="provinceChange"
                                  placeholder="选择省份">
                            <template v-for="(name) in provinces">
                                <a-select-option :key="name" :value="name">{{ name }}</a-select-option>
                            </template>
                        </a-select>
                        <a-select class="at-select city-sel" v-model="params.city" @change="cityChange"
                                  placeholder="选择城市">
                            <template v-for="(name) in cities">
                                <a-select-option :key="name" :value="name">{{ name }}</a-select-option>
                            </template>
                        </a-select>
                        <a-select class="at-select area-sel" v-model="params.area" placeholder="选择区域">
                            <template v-for="(name) in areas">
                                <a-select-option :key="name" :value="name">{{ name }}</a-select-option>
                            </template>
                        </a-select>
                    </div>

                </div>


                <div class="at-item">
                    <div class="at-item-title">是否系统专业学习过形象管理</div>
                    <a-radio-group v-model="params.is_learn">
                        <a-radio-button :value="1">是</a-radio-button>
                        <a-radio-button :value="0">否</a-radio-button>
                    </a-radio-group>
                </div>

                <div class="at-item">
                    <div class="at-item-title">过往学习机构</div>

                    <a-checkbox-group class="at-checkbox-group" v-model="params.school">
                        <template v-for="(name) in schools">
                            <a-checkbox :value="name" :key="name">{{ name }}</a-checkbox>
                        </template>
                    </a-checkbox-group>

                    <at-input class="other-input" v-model="params.school_other"
                              v-if="params.school.indexOf('其他') >= 0 "
                              placeholder="请输入学习机构"/>

                </div>

                <div class="at-item">
                    <div class="at-item-title">拥有证书类型</div>
                    <a-textarea v-model="params.books"></a-textarea>
                </div>

                <div class="at-item">
                    <div class="at-item-title">目前采用哪种美学体系</div>
                    <a-textarea v-model="params.struct"></a-textarea>
                </div>

            </div>


        </a-modal>
    </div>
</template>

<script>
import api from "@/repo/api";
import AtInput from "@/components/AtInput.vue";

export default {
    name: "QA",
    components: {AtInput},
    data() {
        return {
            showQaModal: true,
            ind: [
                "线上美学机构",
                "线下美学机构",
                "线上服装店",
                "线下服装店",
                "其它业态",
            ],
            schools: [
                '西蔓色彩',
                '四季美学',
                '风尚圈',
                '聚尚美',
                '和颜创美',
                '祺馨色彩',
                '形象家',
                '美目世纪',
                '米莱欧',
                '格界',
                '马兰戈尼',
                '帕森斯',
                '中赫时尚',
                '其他'
            ],
            provinces: [],
            cities: [],
            areas: [],
            areaList: {},

            params: {
                province: undefined,
                city: undefined,
                area: undefined,
                ind: "",
                ind_other: "",
                name: "",
                phone: "",
                books: "",
                is_learn: "",
                school: [],
                school_other: "",
                struct: "",
            }
        }
    },
    mounted() {
        this.getAreas()
    },
    methods: {
        getAreas() {
            api.get('/ast-app/areas', (res) => {

                console.log(res.data);

                this.provinces = this.objectKeys(res.data)

                this.areaList = res.data


            })
        },
        objectKeys(obj) {
            let keys = []

            for (const objKey in obj) {
                keys.push(objKey)
            }

            return keys
        },
        provinceChange() {
            let cities = this.areaList[this.params.province]
            this.params.city = undefined
            this.params.area = undefined
            this.cities = this.objectKeys(cities)
            this.areas = []
        },
        cityChange() {
            let areas = this.areaList[this.params.province][this.params.city]
            this.params.area = undefined

            this.areas = areas;
        },
        save() {
            this.$loading.show()

            api.post('/ast-app/save-qa', this.params, (res) => {

                this.$loading.hide()

                this.$message.data(res)

                if (res.code === 0) this.showQaModal = false
            })
        },
    }
}
</script>

<style scoped lang="less">
.tips {
    font-weight: normal;
}

.input-group {
    display: flex;
}

.province-sel {
    width: 120px !important;
}

.city-sel {
    width: 160px !important;
}

.area-sel {
    flex: 1 !important;
}

.at-item {
    margin-top: 32px;

    &:first-child {
        margin-top: 0;
    }
}

.at-item-title {
    margin-bottom: 16px;
}

.date-picker {
    /deep/ .ant-input {
        height: 40px;
        border-radius: 0;
    }

    /deep/ .ant-calendar-range-picker-separator {
        line-height: 30px;
    }
}

.at-title {
    height: 64px;
}

.at-item-two {
    display: flex;
    justify-content: left;
    margin-top: 32px;

    &:first-child {
        margin-top: 0;
    }

    .at-item {
        width: 50%;
        margin-top: 0;

        &:first-child {
            padding-right: 20px;
        }

        &:last-child {
            padding-left: 20px;
        }
    }
}

.at-select {
    /deep/ .ant-select-selection--single {
        height: 40px;
        border-radius: 0;
    }

    /deep/ .ant-select-selection__rendered {
        line-height: 40px;
    }
}

.at-item-content {

    position: relative;

    .input-tips {
        position: absolute;
        right: 5px;
        top: 18px;
        font-size: 12px;
        color: #a4a4a4;
    }
}

.at-textarea {
    height: 96px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 0;
    margin-bottom: 16px;
}

.other-input {
    margin-top: 16px;
    width: 100%;
}
</style>