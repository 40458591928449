<template>
    <div>
        <div class="cursor-pointer" @click="activeModal">续费</div>


        <at-modal width="408px" title="续费"
                  v-model="showRenewModal"
                  @ok="createOrder"
                  confirm-button-text="确定支付"
                  cancel-button-text="取消支付"
                  v-if="info"
        >

            <div class="renew-title">请选择续费年限</div>

            <a-radio-group class="renew-from-item" v-model="params.times">
                <a-radio :value="1">1年</a-radio>
                <a-radio :value="2">2年</a-radio>
                <a-radio :value="5">5年</a-radio>
            </a-radio-group>

            <div class="renew-title">总计费用</div>
            <div class="renew-from-item">
                ￥ {{ (info.amount * params.times).toFixed(2) }}
            </div>

            <template v-if="info.is_pt">
                <div class="renew-title">支付方式</div>

                <a-radio-group class="renew-from-item" v-model="params.type">
                    <a-radio value="online">在线支付</a-radio>
                    <a-radio value="balance">
                        <a-popover>
                            <template #content>
                                <span class="balance-tips">代理商帐户余额 <b>{{ info.money }}</b> 元</span>
                            </template>
                            <span>余额支付</span>
                        </a-popover>
                    </a-radio>
                </a-radio-group>
            </template>

        </at-modal>

        <a-modal v-model="showQrcodeModal" class="custom-modal"
                 :closable="false"
                 :maskClosable="false"
                 :footer="null">

            <div class="pay-qrcode-container flex flex-center" v-if="order">
                <div class="pay-qrcode-title">付款码</div>
                <div class="pay-qrcode-content flex">
                    <div class="black-block"></div>
                    <div class="qrcode-tips">微信扫一扫，向颜创时代科技付款</div>
                    <div class="qrcode">
                        <img :src="qrcode" alt="">
                    </div>
                    <div class="price-info">扫码支付{{ order.price }}元</div>
                </div>
                <div class="pay-close" @click="showQrcodeModal = false">
                    <img src="/assets/icons/modal-close.png" alt="">
                </div>
            </div>


        </a-modal>
    </div>
</template>

<script>
import AtModal from "@/components/AtModal.vue";
import api from "@/repo/api";

export default {
    name: "Renew",
    components: {AtModal},
    data() {
        return {
            showRenewModal: false,
            showQrcodeModal: false,
            info: {},
            params: {
                type: "online",
                times: 1,
            },
            order: {},
            timerFlag: null,
            qrcode: null,
        }
    },
    mounted() {
    },
    methods: {
        createOrder() {
            this.$loading.show()

            api.post('/ast-renew/create-order', this.params, res => {

                this.$loading.hide()

                if (res.code !== 0) return this.$message.data(res)

                this.order = res.data

                this.pay()
            })
        },
        pay() {
            if (this.params.type === 'balance') {
                return this.balancePay();
            }

            return this.qrcodePay();
        },
        balancePay() {
            this.$loading.show()

            api.post('/ast-renew/online-pay', {no: this.order.no}, res => {

                if (res.code !== 0) {

                    this.$message.data(res)
                    return this.$loading.hide();
                }

                this.checkPayStatus()
            })
        },
        checkPayStatus() {
            this.timerFlag = setTimeout(() => {

                api.get('/ast-renew/paid-status', {no: this.order.no}, res => {

                    if (res.code !== 0) return;

                    if (res.data.status) return this.paySuccess()

                    this.checkPayStatus()
                })

            }, 3000)
        },
        clearTimer() {
            clearTimeout(this.timerFlag)
        },
        paySuccess() {

            this.clearTimer()

            this.showRenewModal = false
            this.showQrcodeModal = false

            this.$loading.hide()

            this.$message.success('续费成功');

            setTimeout(() => {

                window.location.reload()

            }, 2000)
        },
        qrcodePay() {

            api.post('/ast-renew/qrcode-pay', {no: this.order.no}, res => {

                if (res.code !== 0) {

                    this.$message.data(res)

                    return this.$loading.hide();
                }

                this.qrcode = res.data.qrcode

                this.showQrcodeModal = true

                this.checkPayStatus()
            })

        },
        activeModal() {
            this.fetchPrices()
        },
        fetchPrices() {

            this.$loading.show()

            api.get('/ast-renew/info', res => {

                this.$loading.hide()

                this.info = res.data

                this.showRenewModal = true
            })
        }
    }
}
</script>

<style scoped lang="less">

.renew-title {
    font-size: 14px;
    color: #333333;
    line-height: 16px;

    margin-top: 24px;

    &:first-child {
        margin-top: 0;
    }
}

.renew-from-item {
    margin-top: 16px;
}

.balance-tips {
    font-size: 14px;
    color: #666666;
    line-height: 16px;
}

.pay-qrcode-container {
    width: 384px;
    height: 541px;
    background-image: url("/assets/images/pay-qrcode-bg-1.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 1px;
    flex-direction: column;
}

.pay-qrcode-title {
    font-weight: 600;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 34px;
    text-align: center;
}

.black-block {
    width: 304px;
    height: 16px;
    background: #333333;
    border-radius: 2px 2px 0 0;
}

.pay-qrcode-content {
    width: 304px;
    height: 399px;
    margin-top: 16px;
    background-image: url("/assets/images/pay-qrcode-bg-2.png");
    background-size: cover;
    background-repeat: no-repeat;
    flex-direction: column;
    align-items: center;
}

.qrcode {
    width: 240px;
    height: 240px;
    border-radius: 2px 2px 2px 2px;
    margin-top: 20px;

    img {
        width: 100%;
    }
}

.qrcode-tips {
    font-weight: 400;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.5);
    line-height: 25px;
    text-align: center;
    margin-top: 20px;
}

.pay-close {
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-top: 20px;

    img {
        width: 100%;
    }
}

.price-info {
    font-weight: 600;
    font-size: 24px;
    color: #333333;
    line-height: 34px;
    cursor: pointer;
}

</style>